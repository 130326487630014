.socialBar {
	font-size: 1.2rem;
	//font-family: motor, monospace;
	font-weight: 400;
	font-style: normal;
}

.iconButton,
.iconEdit,
.iconAddScene,
.iconComment {
	//color: rgb(190, 190, 190);
	font-size: 1rem;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 20px 20px;
	border-width: 0;
	padding-left: 25px;
	margin-right:15px;
	min-width:45px;
}

.iconEdit {
	  background-image: url('../img/icons/pencil-square.svg');
}

.iconAddScene {
	  background-image: url('../img/icons/file-plus.svg');
}

hr.hrSocialBar {
	border-top:1px solid rgb(120, 120, 120);
	border-bottom-width:0;
	margin:3px 0;
}