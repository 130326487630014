@use 'Theme';

.App {color: rgb(60, 60, 60);}

a {color: #29ABE2;}

a:hover, 
a:focus {color: Theme.$dkPurple;}

// Brand
.navbar-brand {
	width:100px;
	height:70px;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 75px auto;
}

// Navbar
.navbar {
	background-color: Theme.$dkPurple;
	border-bottom: 1px solid rgb(0, 0, 0);
	background-image: linear-gradient(to bottom, Theme.$mdPurple, Theme.$dkPurple);
	
	.navbar-nav .nav-link {
		color: #fff;
		font-family: rubrik-new,sans-serif;
		font-weight: 400;
		font-style: normal;
	}
	
	.navbar-nav .nav-link.active, 
	.navbar-nav .show > .nav-link {color: #FBB03B;}
	
	.navbar-nav .nav-link:hover, 
	.navbar-nav .nav-link:focus {color: #FBB03B;}
	
	.navbar-nav .nav-link.active:hover, 
	.navbar-nav .nav-link.active:focus {color: #FBB03B;}
}


#navbarSupportedContent {
	border: 1px solid #000;
	border-width: 1px 0;
	background-color: Theme.$ltPurple;
	padding: 3px 22px 5px;
	margin: 5px -11px 0;
}

@media (min-width: 576px) {
	#navbarSupportedContent {
		border-width: 0;
		background-color: transparent;
		padding: 0;
		margin: 0;
	}
	
	.navbar {padding-bottom: 0.5rem;}
}

.login-link {color:#fff;}
.login-link:hover,
.login-link:focus {color: rgb(255, 109, 0);}


// Navbar - scene
.navbar-scene {
	border-bottom: 1px solid #234b92;
	background-color: #234b92;
}

.navbar-brand-scene {
	width:50px;
	height:30px;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center 0px;
	background-size: 48px auto;
	overflow: hidden;
}


// Hero image
.hero {
	height: 75px;
	border-bottom: 4px solid #4D4D4D;
	position: relative;
	z-index: 0;
	
	p {
		color: #fff;
		font-size: 2.2rem;
		line-height: 2.6rem;
		font-weight: bold;
		margin-top: 0px;
		position: relative;
		z-index: 1;
		text-shadow: 1px 1px 2px black;
		font-family: rubrik-new,sans-serif;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -.04em;
	}
	
	span {
		display:block;
		font-size: 1.2rem;
		line-height: 1.3rem;
		letter-spacing: .08em;
	}
	
	background-image: linear-gradient(to right, #FBB03B, #F15a24, #29ABE2);
}

.hero::before {    
	content: "";
	background-image: url(/img/hero-graphic.svg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 1300px auto;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	z-index: 1;
}

// Panel controls
.panelControls {
	position: fixed;
	z-index:10;
	bottom:0;
	border-top: 1px solid rgb(200, 200, 200);
	background-color: rgba(225, 225, 225, .7);
}


.navPanel .nav-pills .nav-link {
	color: #fff;
	border: 1px solid transparent;
}
	
.navPanel .nav-pills .nav-link.active, 
.navPanel .nav-pills .show > .nav-link {
	color: #fff;
	background-color: transparent;
	border: 1px solid #FBB03B;
}

.navPanel .nav-pills .nav-link:hover, 
.navPanel .nav-pills .nav-link:focus {
	color: #FBB03B;
}

// Headings
h1, h2, h3, h4, h5, h6 {
	font-family: rubrik-new,sans-serif;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -.04em;
}

h1 {font-size: 1.8rem;}
h2 {font-size: 1.5rem;}
h3 {font-size: 1.3rem;}

// Links
a {text-decoration: none;}
a.block p {color: #000;}

// Forms
.btn {
	background-color: #29ABE2;
	border: 1px solid #29ABE2;
	//border: 1px solid white;
	//background-color: rgb(255, 109, 0);
}

.btn-panel {
	color: rgb(100, 100, 100);
	background-color: rgb(200, 200, 200);
	border: 1px solid rgb(200, 200, 200);
}

.btn-panel:hover,
.btn-panel:active {
	color: rgb(100, 100, 100);
	background-color: rgb(220, 220, 220);
	border: 1px solid rgb(200, 200, 200);
}


// Webkit
[type=button] {
	-webkit-appearance: none;
}

// VR
#vr-controls {
	z-index: 2;
	position: absolute;
	top: 20px;
	left: 20px;
	right: 20px;
	height: 60px;
}