a.block, a.block:hover {
	text-decoration:none;
	color: #000;
}

.card {
	//border-width: 2px;
}

.title {
	color: #eaa62d;
	font-size: 1.3rem;
	font-weight: 400;
	font-style: normal;
	text-decoration:none;
	border-bottom: 0;
}

.img img {
	max-width: 100%;
	height: 250px;
	object-fit: cover;
}

.panel {
	color: rgb(210, 210, 210);
	background-color: rgb(90, 90, 90);
	border: 1px solid rgb(50, 50, 50);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	
	.title {
		color: #fff;
	}

	.scroll {
		overflow-x: hidden;
		overflow-y: auto;
		max-height: 400px;
	}
}

