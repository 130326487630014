.heading {
	font-size: .9rem;
	text-align: left;
	font-weight: bold;
}

.objHeader {font-size: 1.1rem;}

.btnIcon {
	border: 1px solid rgb(200, 200, 200);
	width: 75px;
	height: 75px;
	padding: 0;
	margin: 0 auto;
	
	img {
		width: 75px;
		height: 75px;
	}
}

.btnIcon:hover, .btnIcon:focus  {
	box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .45);
	box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .45);
	border-radius: 2px;
}

.collapseMaterial div:last-child hr {display:none;}

.alertPanel {
	position: fixed;
	bottom: 0;
	left: 20px;
	right: 20px;
}

/*** AVATAR SELECT ***/  

.boxObjectSelect {
	width:auto;
	padding: 0;
	
	.dropdownMenu {
		width: 300px;
	}
	
	.containerObjects {margin-bottom: 10px;}
	
	label, .btn, .btnObjectSelect {
		font-size: 10px;
		font-weight: normal;
		color: rgb(90, 90, 90);
		background-color: #fff;
		border: 2px solid rgb(190, 190, 190);
		width: 70px;
		height: 70px;
		padding: 0px;
		margin-bottom: 25px;
		display: block;
		border-radius: 4px;
		position: relative;
		
		background-position: center center;
		background-repeat: no-repeat;
		
		
		span {
			position: absolute;
			top: 73px;
			left: 0;
		}
	}
	.btnObjectSelect {
		color: transparent;
		border: 2px solid rgb(130, 130, 130);
	}
	
	input {display:none;}
	
	label:hover, label:focus, input:checked + label  {
		border-color: rgb(90, 90, 90);
	}
	
	.cube {
		background-image: url('../img/icons/icon-cube.png');
		background-size: 70px 70px;
	}
	.sphere {
		background-image: url('../img/icons/icon-sphere.png');
		background-size: 70px 70px;
	}
	
	.table60in {
		background-image: url('../img/icons/icon-table-60in@x2.png');
		background-size: 70px 70px;
	}
	.table66in {
		background-image: url('../img/icons/icon-table-66in@x2.png');
		background-size: 70px 70px;
	}
	.table72in {
		background-image: url('../img/icons/icon-table-72in@x2.png');
		background-size: 70px 70px;
	}
	
	.table60inChiavari {
		background-image: url('../img/icons/icon-table-60in-chiavari@x2.png');
		background-size: 70px 70px;
	}
	.table66inChiavari {
		background-image: url('../img/icons/icon-table-66in-chiavari@x2.png');
		background-size: 70px 70px;
	}
	.table72inChiavari {
		background-image: url('../img/icons/icon-table-72in-chiavari@x2.png');
		background-size: 70px 70px;
	}
	.table30inCocktail {
		background-image: url('../img/icons/icon-table-30in-cocktail@x2.png');
		background-size: 70px 70px;
	}
	
	.table18inMeeting {
		background-image: url('../img/icons/icon-table-18in-meeting@x2.png');
		background-size: 70px 70px;
	}
	.table30inMeeting {
		background-image: url('../img/icons/icon-table-30in-meeting@x2.png');
		background-size: 70px 70px;
	}
	
	.table18inBanquet {
		background-image: url('../img/icons/icon-table-18in-banquet@x2.png');
		background-size: 70px 70px;
	}
	.table30inBanquet {
		background-image: url('../img/icons/icon-table-30in-banquet@x2.png');
		background-size: 70px 70px;
	}
	
	.chairLancaster {
		background-image: url('../img/icons/icon-chair-lancaster@x2.png');
		background-size: 70px 70px;
	}
	.chairChiavari {
		background-image: url('../img/icons/icon-chair-chiavari@x2.png');
		background-size: 70px 70px;
	}
	.portableBar {
		background-image: url('../img/icons/icon-portable-bar@x2.png');
		background-size: 70px 70px;
	}
	.stageUnit {
		background-image: url('../img/icons/icon-stage-unit@x2.png');
		background-size: 70px 70px;
	}
	.podium {
		background-image: url('../img/icons/icon-podium@x2.png');
		background-size: 70px 70px;
	}
	.videoScreen {
		background-image: url('../img/icons/icon-video-screen@x2.png');
		background-size: 70px 70px;
	}
	.danceFloorUnit {
		background-image: url('../img/icons/icon-dance-floor-unit@x2.png');
		background-size: 70px 70px;
	}
	
}

