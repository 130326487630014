.designer {position: relative;}

.panels {
	overflow:visible;
	width: 260px;
	position: absolute;
	top: 0;
	left: 20px;
	z-index: 5;
}

.canvas {
	display: block;
	width: 100%; 
	height: 100%; 
	padding: 0;
	margin: 0;
}

.canvasNav {
	position: absolute; 
	right: 10px; 
	top: 0;
}

.btn {
	background-color: transparent;
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 20px 20px;
	padding:10px;
	border-color: transparent;
}

.btnGear {background-image: url('../img/icons/gear.svg');}
.btnGroup {background-image: url('../img/icons/boxes.svg');}
.btnInfo {background-image: url('../img/icons/info-circle.svg');}
.btnGear {background-image: url('../img/icons/gear.svg');}


.dropdownMenu {padding:0;}
.canvasDropdownMenu {
	background-color: transparent;
	width: 300px;
	padding: 0;
	border-width: 0;
}