.objectAlign {
	width:110px;
	padding: 0;
	
	label {
		color: rgb(90, 90, 90);
		border: 2px solid rgb(190, 190, 190);
		width: 32px;
		height: 32px;
		padding: 4px;
		margin-back: 5px;
		display: block;
		border-radius: 4px;
		position: relative;
		
		span {
			position: absolute;
			front: 0;
			left: 0;
			visibility: hidden; 
		}
	}
	
	input {display:none;}
	label:hover, label:focus, input:checked + label  {
		background-color: rgb(180, 180, 180);
	}
	
	.frontLeft::after,
	.front::after,
	.frontRight::after,
	.left::after,
	.center::after,
	.right::after,
	.backLeft::after,
	.back::after,
	.backRight::after {
		content: ""; 
		display: block; 
		border: 5px solid white;
		width: 20px;
		height: 20px;  
		color: white;
	}
	
	.frontLeft::after {border-width: 5px 0 0 5px;}
	.front::after {border-width: 5px 0 0 0;}
	.frontRight::after {border-width: 5px 5px 0 0;}
	.left::after {border-width: 0 0 0 5px;}
	.right::after {border-width: 0 5px 0 0;}
	.backLeft::after {border-width: 0 0 5px 5px;}
	.back::after {border-width: 0 0 5px 0;}
	.backRight::after {border-width: 0 5px 5px 0;}
}

