// Default
$ltPurple: #706E86;
$mdPurple: #665E7C ;
$dkPurple: #524A68;
$ltBlue: #3FA9F5;

// Hilton
$hiltonBlue: #234b92;
$hiltonLightBlue: #449ad8;

// Marriot
$marriotRed: #991e29;

@mixin theme-header($name, $color) {
  
	.theme-#{$name} {
		//color: #{$color};
	}
}

// Default
.navbar {
	.navbar-brand {background-image: url('/img/logos/logo-argus-xr.svg');}
	background-image: linear-gradient(to bottom, $mdPurple, $dkPurple);
}

.navbar-scene {
	.navbar-brand {background-image: url('/img/logos/logo-argus-xr.svg');}
}

h1, h2 {color: #29ABE2;}

.card {border:1px solid $mdPurple;}

// Other
.theme-altArgusXR {
	h1, h2 {color: rgb(86, 86, 86);}
	
	.navbar {
		//background-color: rgb(60, 60, 60);
		background-image: linear-gradient(to bottom, rgb(86, 86, 86), rgb(51, 51, 51));
		border-bottom: 1px solid $dkPurple;
	}
}


.theme-hilton {
	
	.navbar {
		background-color: rgb(60, 60, 60);
		border-bottom: 1px solid $hiltonBlue;
	}
}

.theme-marriot {
	
	.navbar {
		background-color: $marriotRed;
		border-bottom: 1px solid $marriotRed;
	}
}

@include theme-header("hilton", blue);
@include theme-header("marriot", red);